import logo from './logo.svg';
import './App.css';
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavD from "./NavD";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import michalIntro from "./michalIntro.png";
import {CcCircle, CCircle, Circle, List} from "react-bootstrap-icons";
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import WhyMePage from "./WhyMePage";
import PhilosophyPage from "./PhilosophyPage";
import Pricepage from "./PricePage";
import WhyTrainer from "./WhyTrainer";
import AboutMePage from "./AboutMePage";
import {useSpring, animated, config} from "@react-spring/web";
import ImageViewer from "./ImageViewer";
import ronnie from './ronnie.jpg';
import roubik from './roubik.jpg';
import trenerska_praxe from './trenerska_praxe.jpg';
import useOnScreen from "./Observer";


function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
function App() {
    const [width, height] = useWindowSize();
    const [isImageViewerVisible, setImageViewerVisible] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');
    const [isLoad, setIsLoad] = useState(false);

    const ref = useRef();

    const [intersects, setIntersecting] = useState(false);
    const isVisible = false;

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref]);

    const [springs, api] = useSpring(() => ({
        from: {transform: "translateX(-300%)"},
        config: config.slow
    }));

    const handleImageClick = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setImageViewerVisible(true);
    };

    const handleCloseImage = () => {
        setImageViewerVisible(false);
        setSelectedImageUrl('');
    };


    const props = useSpring({
        from: {transform: "translateX(-100%)", opacity: 0, marginTop: "12%"},
        to: {transform: "translateX(0)", opacity: 1, marginTop: "12%"},
        config: config.slow
    })

    const props2 = useSpring({
        from: {transform: "translateX(-200%)", opacity: 0, marginTop: "15px"},
        to: {transform: "translateX(0)", opacity: 1, marginTop: "15px"},
        config: config.slow
    })

    const propsServices = useSpring({


        config: config.slow
    });

    useEffect(() => {
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {

        if(intersects && isLoad === false) {
            setIsLoad(true);
            api.start({
                from: {transform: "translateX(-300%)"},
                to: {transform: "translateX(0)"}
            })
        }
    }, [intersects]);

  return (
    <div className="App" style={{height: "100%"}} >
        <NavD/>

        <div style={{backgroundColor: "#1a1a1a"}} className="d_container">

            {/*<div id="main_bg" style={{height: "90vh", width: "100%", margin: 0, padding: 0}}>*/}
            <div id="main_bg2" style={{height: "90vh", width: "100%", margin: 0, padding: 0, borderTop: "4px solid white"}}>
                {/*<img src={michalIntro} alt="logo"*/}
                {/*     style={{height: "100%", paddingTop: "10vh"}}/>*/}
                <Image src={require("./allah2.jpg")} id={{}}
                       style={{width: "100%", height: "100%",
                           objectFit: "cover",
                           objectPosition: "center top",
                           left: 0,
                }} />

                <animated.h1 style={{...props, padding: 15}} className="main-header">
                    <div style={{ backgroundColor: "#020023", padding: 5, paddingRight: 20}}>Michal Mestek</div>

                    <animated.h2 style={{...props2}} className="main-header-prop">osobní trenér</animated.h2>
                </animated.h1>

            </div>

            <div className="d_container" style={{
                height: width < 790 || height < 730 ? "100%" : "100%",
                paddingTop: 0,
                margin: 0,
                width: "100%",
                borderTop: "12px double white",
            }}>

                <h1 ref={ref} id="why" style={{color: "white", paddingTop: "90px", fontWeight: "bold"}}><span
                    className="underline"> Co Vám mohu nabídnout </span></h1>

                <animated.ul className="main-cols"
                             style={{
                                 width: width < 1250 ? "90%" : "60%",
                                 margin: "0 auto",
                                 paddingTop: "80px",
                                 textAlign: "left", ...springs
                             }}
                >
                    <li style={{marginTop: "15px"}}>
                        <p><span className="why_span">lidský přístup ke cvičení</span>, fitness je můj koníček,
                            nikoli
                            jediný
                            smysl mé existence
                        </p>
                    </li>
                    <li style={{marginTop: "15px"}}>
                        <p>s klienty mám <span className="why_span">osobní vztah</span>, nejsou pro mě jen chodící
                            prasátko
                            na peníze</p>
                    </li>
                    <li style={{marginTop: "15px"}}>
                        <p>co nejhladší zakomponování fitka do Vaší týdenní rutiny</p>
                    </li>
                    <li style={{marginTop: "15px"}}>
                        <p><span className="why_span">trénuji profesionálně</span>, neflákám se, při tréninku mě na
                            mobilu
                            opravdu neuvidíte</p>
                    </li>
                    <li style={{marginTop: "15px"}}>
                        <p>nejsem sadista, <span className="why_span">neutrápím vás</span>, chcete mít výsledky, ne se
                            zničit tak, že se nebudete moci týden
                            pohnout</p>
                    </li>
                    <li style={{marginTop: "10px"}}>
                        <p>neřídím se žádnými dogmaty, snažím se opírat své tréninkové metody o nejnovější <span
                            className="why_span">vědecké studie</span>,
                            jsem si ale dobře vědom toho, že mají svá hluchá místa.</p>
                    </li>
                </animated.ul>
                <br/>

                {/*<p style={{*/}
                {/*    fontSize: "1.2em",*/}
                {/*    color: "white",*/}
                {/*    width: width < 1350 ? "80%" : "60%",*/}
                {/*    margin: "0 auto",*/}
                {/*    textAlign: "left"*/}
                {/*}}>Sem si každý zpravidla píše to samé, jsou to jen dobře znějící hesla, o kvalitě trenéra se zpravidla*/}
                {/*    nic*/}
                {/*    nedozvíte. Co potřebujete vědět: Když budete pod mým dohledem pár měsíců cvičit ve fitku, tak to*/}
                {/*    na sobě poznáte. </p>*/}

                <h1 id="cert" style={{color: "white", paddingTop: "5%", fontWeight: "bold"}}><span
                    className="underline"> Certifikace </span></h1>

                <div className="main-rows"
                     style={{
                         justifyContent: "center", width: "80%", margin: "0 auto", paddingTop: "50px",
                         flexDirection: width < 1200 ? "column" : "row"
                     }}>
                    <Col
                         className={width < 1200 ? "underline-short" : "offer-container off-r"}>
                        <p >Ronnie.cz - Instruktor fitness</p>
                        <Button onClick={() => handleImageClick("ronnie")} variant="outline-light">Klikni</Button>
                    </Col>
                    <Col
                         className={width < 1200 ? "underline-short" : "offer-container  off-r"}>
                        <p onClick={() => handleImageClick("trenerska_praxe")}>Form Factory: Trenérská praxe I.</p>
                        <Button onClick={() => handleImageClick("trenerska_praxe")} variant="outline-light">Klikni</Button>
                    </Col>
                    <Col className={width < 1200 ? "underline-short" : "offer-container"}>
                        <p onClick={() => handleImageClick("roubik")}>Institut Moderní výživy - Dosáhni naturálního
                            maxima</p>
                        <Button onClick={() => handleImageClick("roubik")} variant="outline-light">Klikni</Button>
                    </Col>

                </div>

                {
                    selectedImageUrl === "ronnie" ?
                        <ImageViewer width={width} height={height} imageUrl={ronnie} onClose={handleCloseImage}/>
                        : selectedImageUrl === "roubik" ?
                            <ImageViewer width={width} height={height} imageUrl={roubik} onClose={handleCloseImage}/>
                            : selectedImageUrl === "trenerska_praxe" ?
                                <ImageViewer width={width} height={height} imageUrl={trenerska_praxe}
                                             onClose={handleCloseImage}/> : null
                }
            </div>

            <WhyMePage width={width} height={height}/>
            <PhilosophyPage width={width} height={height}/>
            <WhyTrainer width={width} height={height}/>
            {/*<AboutMePage width={width} height={height}/>*/}
            <Pricepage width={width} height={height}/>

            <div className="d_container" style={{
                justifyContent: "center",
                height: width > 1250 && height > 760 ? "100%" : "100%",
                paddingTop: "160px",
                paddingBottom: "10px"
            }}>
                <center>
                    <p style={{color: "white"}}>Copyright <CCircle></CCircle> 2024 Domped</p>
                </center>
            </div>
        </div>
    </div>
  );
}

export default App;
