import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import "./index.css";
import {List} from "react-bootstrap-icons";
import bs from "./bs_logo.png";
import {config, animated, useSpring} from "@react-spring/web";

function NavD() {

    const props1 = useSpring({
        from: {transform: "translateX(-300%)", opacity: 0, height: 100, marginTop: 10 },
        to: {transform: "translateX(0)", opacity: 1, height: 100, marginTop: 10},
        config: config.slow
    })

    const props2 = useSpring({
        from: {transform: "translate(400%)", opacity: 0},
        to: {transform: "translateX(0)", opacity: 1},
        config: config.slow
    })

    return (
        <Navbar
            style={{
                backgroundColor: "#020023",
                top: 0,
                width: "100%",
                zIndex: 100,
                fontFamily: "Nunito"
            }}
            className="bg-opacity-0">

            <Container style={{}}>
                <Navbar.Brand className="text-white" href="#home">
                    <animated.img src={bs} style={props1} alt={"logo"}/>
                </Navbar.Brand>
                    <NavDropdown drop={"start"} title={<List size={50}/>} className="nav-dropdown-remove-arrow" >
                        <NavDropdown.Item href="#why">Proč si vybrat mě?</NavDropdown.Item>
                        <NavDropdown.Item href="#cert">Certifikace</NavDropdown.Item>
                        <NavDropdown.Item href="#services">Služby</NavDropdown.Item>
                        <NavDropdown.Item href="#presvedceni">Moje přesvědčení</NavDropdown.Item>
                        <NavDropdown.Item href="#whytrainer">Proč trenér</NavDropdown.Item>
                        <NavDropdown.Item href="#price">Ceník</NavDropdown.Item>
                        <NavDropdown.Item href="#where">Kde trénuji</NavDropdown.Item>
                        <NavDropdown.Item href="#contact">Kontakt</NavDropdown.Item>
                    </NavDropdown>
            </Container>
        </Navbar>
    )
}

export default NavD;
