import {useState} from "react";
import {ArrowRight} from "react-bootstrap-icons";
import asian from "./asian2.jpg";
import deadlift from "./maki_deadlift.jpg";

export default function WhyTrainer({width, height}) {

    const [isShortVisible, setIsShortVisible] = useState(true);
    const [isLongVisible, setIsLongVisible] = useState(false);

    return (
        <div className="d_container" style={{
            justifyContent: "center",
            height: width > 1250 && height > 760 ? "100%" : "100%",
            paddingTop: "160px"
        }}>

            <h1 id="whytrainer" style={{color: "white", fontWeight: "bold"}}><span className="underline">Proč vůbec cvičit s trenérem?</span></h1>
            <br/>
            <h4 style={{color: "white"}}><span style={{fontSize: "2.4em"}}>...</span> všechno se dozvím na internetu
                zdarma</h4>

            <div style={{width: width < 1200 ? "80%" : "60%", margin: "0 auto"}}>
                <p style={{color: "white", fontSize: "1.3em", textAlign: "left", marginBottom: "0", marginTop: "60px"}}>

                    Dozvíte se sice všechno, ale jako začátečník nebudete mít nikdy jistotu, že do vás někdo nesype
                    úplné
                    hlouposti. Bez trenéra si musíte všechno sami vyzkoušet a otestovat. Ztratíte tak spoustu času, s
                    minimálními výsledky a tělo si tím zpravidla i trochu poroucháte.

                    <br/>
                    <br/>

                    Dobrý trenér prostě zajistí, že váš čas ve fitku bude efektivně využitý, poskytne vám oporu a obecně
                    vám celý proces výrazně ulehčí.

                </p>
            </div>

            <button style={{
                textDecoration: "none",
                fontSize: "1.3em",
                fontWeight: "bold",
                paddingBottom: "10px",
                color: "white",
                backgroundColor: "transparent",
                border: 0,
                marginTop: "40px",
                marginBottom: isLongVisible ? "10px" : "80px"
            }} onClick={() => {
                setIsLongVisible(!isLongVisible);
            }} className={isLongVisible ? "underline" : ""}>
                Pro ty co mají minutku<ArrowRight/>
            </button>

            <div></div>
            {isLongVisible ?
                <div style={{
                    width: width < 1200 ? "80%" : "60%",
                    margin: "0 auto",
                    marginTop: "40px",
                    textAlign: "left"
                }}>
                    <p style={{color: "white", fontSize: "1.3em"}}>
                        Odlišit validní názor založený na datech od výplodu nějakého samozvaného odborníka, je pro
                        člověka, co
                        se
                        v oboru nepohybuje delší dobu, prakticky nemožné.
                        <br/>
                        <br/>
                        Problém je v tom, že posilování klade na začátečníka poměrně nekompromisní nároky. Člověk prostě
                        musí vědět, co jak funguje, musí znát svalovou anatomii a mít aspoň základní stravovací návyky.
                        Jako
                        ve všem, i zde lze aplikovat technika pokus/omyl. Pokud chcete mít výsledky, tak vás bez odborné
                        pomoci trenéra čeká jen jediná cesta, a to cesta sebevzdělání, zkoušení různých tréninkových
                        postupů a tipů, které vám předá některý fitness influencer, případně zkušenější návštěvník
                        vašeho
                        fitka.
                        <br/>
                        <br/>
                        Připravte se na to, že strávíte několik let točením se v bludných kruzích, než se vám podaří
                        zorientovat a z dostupných informací vydestilovat skutečně fungující principy.
                        <br/>
                        <br/>
                        Bude to bolet, ztratíte spoustu času děláním zbytečností. Nevyhnutelně si způsobíte nějaká
                        zranění a
                        svalové dysbalance, i když se jim budete snažit co nejvíce předcházet.
                        <br/>
                        <br/>
                        Zde mluvím z osobní zkušenosti, byla to i moje cesta, stejně jako většiny lidí v mém okolí.
                        Náprava
                        škod způsobených nevhodným cvičením vám pak může zabrat i několik let.
                        <br/>
                        <br/>
                        Druhou, mnohem přímočařejší, rychlejší a bezpečnější variantou, je vyhledat pomoc odborníka.
                        Neříkám, že to bez něj nejde, ale bude to stát mnohem více času a úsilí.

                    </p>
                </div>

                : null
            }

            <img style={{borderWidth: "15px", borderColor: "white", borderStyle: "double", marginBottom: "90px"}} className="trainer-image"
                 src={asian} alt="trener-img"/>
        </div>
    );
}