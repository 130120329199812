import deadlift from "./maki_deadlift.jpg";
import back from "./maki_back.jpg";
import {useEffect, useMemo, useRef, useState} from "react";
import {config, useSpring, animated} from "@react-spring/web";
export default function WhyMePage({width, height}) {

    const ref2 = useRef();
    const [intersectsWhy, setIntersectingWhy] = useState(false);
    const [isLoad, setIsLoad] = useState(false);


    const observerWhy = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersectingWhy(entry.isIntersecting)
    ), [ref2]);


    const [springs2, api2] = useSpring(() => ({
        from: {transform: "translateX(-300%)"},
        config: config.slow
    }));

    useEffect(() => {
        observerWhy.observe(ref2.current);
        return () => observerWhy.disconnect();
    }, []);

    useEffect(() => {
        if(intersectsWhy && isLoad === false) {
            setIsLoad(true);
            api2.start({
                from: {transform: "translateX(-300%)"},
                to: {transform: "translateX(0)"}
            })
        }
    }, [intersectsWhy]);

    return (
        <div className="d_container" style={{
            justifyContent: "center",
            height: width > 1250 && height > 760 ? "100%" : "100%",
            paddingTop: "80px"
        }}>

            <h1 ref={ref2} id="services" style={{color: "white", fontWeight: "bold"}}><span
                className="underline"> Služby </span></h1>

            <animated.ul className="main-cols"
                         style={{
                             width: width < 1250 ? "80%" : "60%",
                             margin: "0 auto",
                             paddingTop: "80px",
                             textAlign: "left",
                             ...springs2
                         }}>
                <li>
                    <p>profesionální zasvěcení do světa fitness</p>
                </li>
                <li>
                    <p>formování postavy</p>
                </li>
                <li>
                    <p>nabírání svalové hmoty</p>
                </li>
                <li>
                    <p>náprava svalových dysbalancí, posílení zaostávajících partií</p>
                </li>
                <li>
                    <p>redukce tělesného tuku</p>
                </li>
                <li>
                    <p>nutriční poradenství</p>
                </li>
                <li>
                    <p>a tak dále, tady si každý zpravidla píše to samé</p>
                </li>

            </animated.ul>

            <animated.p style={{textAlign: "center", fontSize: "1.2em", margin: "10px auto", padding: 0, color: "white", ...springs2}}><span
                className="why_span">Co potřebujete vědět:</span></animated.p>
            <animated.p style={{textAlign: "center", fontSize: "1.2em", margin: "0 auto", padding: 0, color: "white",  ...springs2}}>Když budete pod mým
                dohledem pár měsíců cvičit ve fitku, tak to na sobě poznáte, za to Vám ručím.</animated.p>

            <div style={{marginTop: "80px"}}>
                <img style={{borderWidth: "15px", borderColor: "white", borderStyle: "double"}}
                     className="deadlift-image" src={deadlift} alt="deadlift"/>
                {/*<img src={back} alt="back" style={{height: "23.4vw", marginTop: "80px"}}/>*/}
            </div>

        </div>
    );
}