import "./App.css"
import {Col} from "react-bootstrap";
import {useState} from "react";
import {ArrowRight} from "react-bootstrap-icons";

export default function PhilosophyPage({width, height}) {

    const [isShortVisible, setIsShortVisible] = useState(true);
    const [isLongVisible, setIsLongVisible] = useState(false);

    return (
        <div className="d_container" style={{
            justifyContent: "center",
            height: width > 1250 && height > 760 ? "100%" : "100%",
            paddingTop: "160px"
        }}>
            <h1 id="presvedceni" style={{color: "white", fontWeight: "bold"}}><span className="underline"> Moje osobní přesvědčení </span></h1>

            <div style={{width: width < 1200 ? "80%" : "60%", margin: "0 auto"}}>
                <p style={{color: "white", fontSize: "1.3em", textAlign: "left", marginBottom: "0", marginTop: "60px"}}>
                    Nemusíte kolem fitness postavit celou svoji osobnost a věnovat mu veškerý volný čas, abyste měli
                    velmi dobré výsledky a byli na tom fyzicky lépe, než 90% populace. Stačí jen vědět, co a jak dělat a
                    trochu makat.

                    <br/>
                    <br/>
                    Vím, zní to šíleně, influenceři a média se snaží tlačit opak, ale taková je realita. Cvičit
                    může každý, bez ohledu na nabitost svého harmonogramu, stačí jen trochu vůle a ty 4 hodiny týdně
                    prostě někde najdete.
                </p>
            </div>
        </div>
    );
}