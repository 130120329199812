import React from 'react';
import {X} from "react-bootstrap-icons";

const ImageViewer = ({ wwidth, hheight, imageUrl, onClose }) => {
    return (
        <div onClick={onClose} style={{position: "fixed", top: 0, left:"50%", transform:"translate(-50%, 0)",
                margin:"0 auto", height: "100vh", width: "100vw", zIndex: 100000}}>
            <img style={{objectFit: "contain", width: "100%", height: "100%"}} src={imageUrl} alt="Fullscreen"/>
        </div>
    );
};

export default ImageViewer;