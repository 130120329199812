import {useEffect, useMemo, useRef, useState} from "react";
import emailjs from "@emailjs/browser";
import {useSpring, animated, config} from "@react-spring/web";




export default function Pricepage({width, height}) {

    const [emailText, setEmailText] = useState("");
    const [areaText, setAreaText] = useState("");

    const form = useRef();

    const SendEmail = (e) => {

        e.preventDefault();

        emailjs.sendForm("service_j0m3pyd", "template_6q6c5jc", form.current, "2fkOcL7Oj5Kz2VR43")
            .then((result) => {
                console.log(result.status);
            }, (error) => {
                console.log(error.text);
            });
    }

    const [springs, api] = useSpring(() => ({
        from: {transform: "translateX(-300%)"},
        config: config.slow
    }));

    const [isLoad, setIsLoad] = useState(false);

    const ref = useRef();

    const [intersects, setIntersecting] = useState(false);
    const isVisible = false;

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref]);

    useEffect(() => {
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {

        if(intersects && !isLoad) {
            setIsLoad(true);
            api.start({
                from: {transform: "translateX(-300%)"},
                to: {transform: "translateX(0)"}
            })
        }
    }, [intersects]);

    // const props2 = useSpring({
    //     from: {transform: "translateX(-200%)", opacity: 0, marginTop: "30px"},
    //     to: {transform: "translateX(0)", opacity: 1, marginTop: "30px"},
    //     config: config.slow
    // })


    return (
        <div className="d_container" style={{
            justifyContent: "center",
            height: width > 1250 && height > 760 ? "100%" : "100%",
            paddingTop: " px"
        }}>
            <h1 id="price" style={{color: "white", fontWeight: "bold"}}><span className="underline"> Ceník </span></h1>


            <div ref={ref} className="main-rows" style={{
                paddingTop: "3%",
                justifyContent: "center",
                width: "100%",
                margin: "0 auto",
                paddingBottom: "1px",
                flexWrap: "wrap"
            }}>

                <animated.ul className="main-cols"
                             style={{
                                 width: width < 1250 ? "90%" : "60%",
                                 margin: "0 auto",
                                 paddingTop: "0px",
                                 textAlign: "left", ...springs
                             }}
                >
                    <li style={{marginTop: "15px"}}>
                        <p><span className="why_span">Žádné fixní ceny nemám</span>. Vše je individuální a záleží na domluvě a okolnostech (frekvence
                            spolupráce, studentský status, časová flexibilita klienta). </p>
                    </li>
                    <li style={{marginTop: "15px"}}>
                        <p>Nebojte se zeptat, <span className="why_span">za zkušební tréninky si nic neúčtuji</span>, při nejhorším si každý půjdeme svou
                            cestou 😊 </p>
                    </li>
                </animated.ul>
                {/*<animated.div*/}
                {/*    style={{*/}
                {/*        width: width > 1300 ? "45%" : width > 1000 ? "40%" : "60%",*/}
                {/*        margin: "20px", ...springs*/}
                {/*    }}>*/}
                {/*    /!*style={{border: "4px solid white", padding: "24px 48px", margin: "2%"}}*!/*/}
                    {/*    <div>*/}
                    {/*        /!*<h4> Jednorázový trénink</h4>*!/*/}
                    {/*        <p style={{fontSize: "1.4em", fontWeight: "bold"}}>*/}
                    {/*            Žádné fixní ceny nemám. Vše je individuální a záleží na domluvě a okolnostech (frekvence*/}
                    {/*            spolupráce, studentský status, časová flexibilita klienta).*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</animated.div>*/}
                    {/*<animated.div*/}
                    {/*    style={{width: width > 1300 ? "20%" : width > 1000 ? "40%" : "60%", margin: "20px", ...springs}}>*/}
                    {/*    <div style={{border: "4px solid white", padding: "24px 48px", height: "80%", margin: "2%"}}>*/}
                    {/*        <h4>Studentský trénink pro dva</h4>*/}
                    {/*        <p style={{fontSize: "1.4em", fontWeight: "bold"}}>*/}
                    {/*            250 Kč / osoba*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</animated.div>*/}
                    {/*<animated.div*/}
                    {/*    style={{width: width > 1300 ? "20%" : width > 1000 ? "40%" : "60%", margin: "20px", ...springs}}>*/}
                    {/*    <div style={{*/}
                    {/*        border: "4px solid white",*/}
                    {/*        padding: "24px 48px",*/}
                    {/*        height: "80%",*/}
                    {/*        margin: "2%"*/}
                    {/*    }}>*/}
                    {/*        <h4> Dlouhodobá spolupráce</h4>*/}
                    {/*        <p style={{fontSize: "1.4em", fontWeight: "bold"}}>*/}
                    {/*            Cena po dohodě*/}
                    {/*        </p>*/}
                    {/*        <br/>*/}
                    {/*    </div>*/}
                    {/*</animated.div>*/}


            </div>

            {/*<animated.div*/}
            {/*    style={{*/}
            {/*        width: width > 1300 ? "45%" : width > 1000 ? "40%" : "60%",*/}
            {/*        margin: "20px", ...springs,*/}
            {/*        marginLeft: "auto",*/}
            {/*        marginRight: "auto"*/}
            {/*    }}>*/}
            {/*    <div style={{border: "4px solid white", padding: "24px 48px", margin: "2%"}}>*/}
            {/*        /!*<h4> Jednorázový trénink</h4>*!/*/}
            {/*        <p style={{fontSize: "1.4em", color: "white"}}>*/}
            {/*            Nebojte se zeptat, <span className="why_span">za zkušební tréninky si nic neúčtuji</span>, při nejhorším si každý půjdeme svou*/}
            {/*            cestou 😊*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</animated.div>*/}
            {/*<center><p style={{color: "white", width: "75vw"}}>*/}
            {/*    Dlouhodobá spolupráce po dobu minimálně tří měsíců (kratší nemají smysl, výsledky trvají nějaký čas),*/}
            {/*    kompletní trenérský servis, tréninkový plán, nutriční*/}
            {/*    poradenství, přímý kontakt přes WhatsApp.*/}
            {/*</p>*/}
            {/*</center>*/}
            <h1 id="where" style={{color: "white", marginTop: "80px", fontWeight: "bold"}}><span
                className="underline"> Kde trénuji </span>
            </h1>

            <iframe
                className="map_allah"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2557.8357285862885!2d14.413023076951143!3d50.12679581061429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bead97220c293%3A0xae9c7fd12892d69!2sArmy%20Gym!5e0!3m2!1scs!2scz!4v1714088000501!5m2!1scs!2scz"
                width="70%" height={width <= 600 ? "350" : "600"}
                style={{marginTop: 20, marginLeft: "auto", marginRight: "auto"}}
                allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>


            <h1 id="contact" style={{color: "white", marginTop: "80px", fontWeight: "bold"}}><span
                className="underline"> Kontakt </span>
            </h1>
            <div style={{}}>
                <h4 style={{color: "white", marginTop: "40px"}}>WhatsApp nebo SMS na číslo:</h4>
                <h4><span
                    style={{fontWeight: "bold", color: "white"}}>774 998 417</span></h4>
            </div>
            <br/>
            <h4 style={{color: "white", marginTop: "40px"}}>Případně využijte kontaktní formulář:</h4>
            <div className="footer" style={{color: "white"}}>
                <form ref={form} className="contactForm" style={{width: width < 1000 ? "100%" : "45%"}}
                      onSubmit={SendEmail}>
                    <label style={{marginTop: "40px"}}>Váš email</label>
                    <input style={{color: "black", fontWeight: "bold"}} value={emailText} name="client_mail"
                           onChange={(event) => {
                               setEmailText(event.target.value);
                           }} type="email"/>

                    <label style={{marginTop: "40px"}}>Co pro Vás mohu udělat?</label>
                    <textarea style={{color: "black", fontWeight: "bold"}} value={areaText} name="message"
                              onChange={(event) => {
                                  setAreaText(event.target.value);
                              }}/>

                    <button type="submit">Odeslat</button>
                </form>
            </div>
            <br/>
            <br/>
            <br/>
            <h3 style={{color: "white", fontWeight: "bold", padding: 0, margin: 0}}>
                Děkuji za Váš čas, těším se na společnou spolupráci a snad se brzy uvidíme v gymu 💪
            </h3>

        </div>
    );
}